@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

.header {
    height: 4.7rem;
    background-color: black;
}

.header .logo {
    color: white;
    float: left;
    margin-left: 0.8rem;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.6rem;
}

.header .wait {
    margin-top: 1.54rem;
    color: white;
    float: right;
    margin-right: 0.8rem;
    font-family: 'Abel', sans-serif;
    font-size: 1.2rem;
}

.header .final {
    border: 0.5px solid white;
    margin-top: 1.25rem;
    color: white;
    background-color: rgb(23, 23, 23);
    float: right;
    padding: 4px 12px;
    margin-right: 0.8rem;
    font-family: 'Abel', sans-serif;
    font-size: 1.2rem;
}

.header .final:hover {
    background-color: rgb(214, 214, 214);
    color: black;
    border: 0.5px solid rgb(214, 214, 214);
}

.content {
    display: flex;
    height: calc(100vh - 4.7rem);
    overflow-y: scroll;
    overflow-x: scroll;
    position: sticky;
    color: black;
    -ms-overflow-style: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.content .iframe {
    height: 100%;
    width: 100%;
}

.errres {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
}

.errres .mainerr {
    color: #ffffff;
    text-align: center;
    position: absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.errres .mainerr .errtxt {
    font-family: 'Titillium Web', sans-serif;
    font-size: 18px;
    padding: 0px 5px;
    margin-top: 0;
}

.errres .mainerr .bigerrtxt {
    margin-top: -2px;
    font-family: monospace;
    font-size: 35px;
    padding: 0px 5px;
    margin-bottom: .5rem;
}